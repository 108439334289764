import { guid } from '../utils/utils';
import { storage } from '../services/storage';

export const initAnalytics = () => {
    const visitId = getVisitId();
    heap.identify(visitId);

    const deviceId = getDeviceId();
    heap.addUserProperties({ deviceId });
}

export const getVisitId = () => {
    let visitId = storage.getItem('visitId');
    if (!visitId) {
        visitId = guid();
        storage.setItem('visitId', visitId);
    }
    return visitId;
};

export const getDeviceId = () => {
    let deviceId = storage.getItem('deviceId');
    if (!deviceId) {
        deviceId = guid();
        storage.setItem('deviceId', deviceId);
    }
    return deviceId;
};

export const trackEvent = (eventType, data) => {
    heap.track(eventType, data);
};

export const pageView = (data) => {
    heap.track('pageView', data);
};

