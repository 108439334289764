const PROPERTY_STATUS_FILTERS = {
    ALL: 'All',
    FOR_RENT: 'For Rent',
    FOR_SALE: 'For Sale',
    OCCUPIED: 'Occupied',
    PENDING: 'Pending'
};

export {
    PROPERTY_STATUS_FILTERS
};