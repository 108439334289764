import './properties.sass';
import Property from '../property/property';
import { splitEvery } from '../../utils/utils';
import { useEffect, useState } from 'react';
import { getAllProperties } from '../../services/property';
import { trackEvent } from '../../analytics/service';
import { PROPERTY_STATUS_FILTERS } from '../../constants/properties';

const PropertiesPage = () => {
    const [properties, updateProperties] = useState([]);
    const [filteredProperties, updateFilteredProperties] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedPropertiesFilter, updateSelectedPropertiesFilter] = useState(PROPERTY_STATUS_FILTERS.ALL);
    useEffect(() => {
        getAllProperties()
            .then((res) => {
                const sorted = sortProperties(res.data);
                updateProperties(sorted);
                updateFilteredProperties(splitEvery(sorted, 3));
            })
            .catch(err => {
                console.log('properties fetch error', err);
            })
            .finally(() => setLoading(false));
    }, []);

    const filterProperties = (filter) => {
        updateSelectedPropertiesFilter(filter);
        let numberOfProperties;
        if (filter === PROPERTY_STATUS_FILTERS.ALL) {
            numberOfProperties = properties.length;
            updateFilteredProperties(splitEvery(properties, 3));
        } else {
            const filtered = properties.filter((property) => property.status === filter);
            numberOfProperties = filtered.length;
            updateFilteredProperties(splitEvery(filtered, 3));
        }

        trackEvent('select_filter', {
            propertyFilter: filter,
            numberOfProperties
        });
    };

    const PropertiesFilter = () => {
        return <>
            <div className="field">
                <div className="control">
                    <div className="select">
                        <select onChange={(e) => filterProperties(e.target.value)} value={selectedPropertiesFilter}>
                            {
                                Object.keys(PROPERTY_STATUS_FILTERS).map((filterKey, idx) => {
                                    const filterValue = PROPERTY_STATUS_FILTERS[filterKey];
                                    return <option key={`property-filter-${idx}`} value={filterValue}>
                                        {filterValue}
                                    </option>
                                })
                            }
                        </select>
                    </div>
                </div>
            </div>
        </>
    };

    const sortProperties = (items = []) => {
        return items.sort((a, b) => {
            if (a.status === PROPERTY_STATUS_FILTERS.PENDING && b.status !== PROPERTY_STATUS_FILTERS.PENDING) {
                return 1;
            }
            
            if (a.status === PROPERTY_STATUS_FILTERS.PENDING && b.status === PROPERTY_STATUS_FILTERS.PENDING) {
                return 0;
            }
            return -1;
        });
    };

    const NoPropertiesMessage = () => (
        <div className="properties-message">
            <h2 className="title is-4">No properties to show. Try adjusting your filters.</h2>
        </div>
    );

    const analyticsSelectAction = (property) => {
        const propertyWithoutImages = { ...property };
        delete propertyWithoutImages.images;
        trackEvent('select_property', propertyWithoutImages);
    };

    return (
        <div className={`pf-properties app-content`}>
            <h1 className="title is-2" style={{ textAlign: 'center' }}>Our Beautiful Homes</h1>
            {loading && <div className="loading-wrapper"></div>}
            {!loading &&
                <>
                    <PropertiesFilter />
                    {
                        filteredProperties?.length ? filteredProperties.map((propertySet, idx) => {
                            const propertySetKey = `property-set-${idx}`;
                            return <div key={propertySetKey} className="columns">
                                {propertySet.map((property) => {
                                    return (
                                        <div key={`${propertySetKey}-property-${property.id}`} className="column is-narrow is-one-third" onClick={() => analyticsSelectAction(property)}>
                                            <Property data={property} imgSrc={property.images?.cover} />
                                        </div>
                                    );
                                })}
                            </div>
                        }) : <NoPropertiesMessage />
                    }
                </>
            }
        </div>
    );
}

export default PropertiesPage;
