
import './card.sass';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const Card = (props) => {
    const { cardTitle, cardTitleIcon, content, expanded, linkToInsert } = props;
    const [cardExpanded, setCardExpanded] = useState(expanded ?? false);

    useEffect(() => {
        setCardExpanded(cardExpanded);
    }, [cardExpanded]);
    const getCardContent = () => {
        // This method is super hacky and has no business being in this file.
        if (Array.isArray(content)) {
            return <ul>
                {content.map((listItem, i) => {
                    const listItemKey = `card-content-item-${i}`;
                    if (linkToInsert) {
                        return <li key={listItemKey}>{parseContent(listItem)}</li>
                    }
                    return <li key={listItemKey}>{listItem}</li>
                })}
            </ul>
        } else {
            return linkToInsert ? <p>{parseContent(content)}</p> : <p>{content}</p>;
        }
    };

    const parseContent = (listItem) => {
        const tokens = listItem.split(linkToInsert.placeholder);
        if (tokens.length > 1) {
            return <>
                {tokens[0]}<Link to={linkToInsert.href}>{tokens[1]}</Link>{tokens[2]}
            </>;
        }
        else {
            return <>{tokens[0]}</>;
        }
    }

    return (
        <div className="card">
            <header className="card-header" onClick={() => setCardExpanded(!cardExpanded)}>
                {/* Margin bottom is reset in the p element to counter the content class if the card is under that */}
                <p className="card-header-title" style={{ 'marginBottom': 0 }}>
                    {cardTitleIcon && <span className="icon">
                        <i className={cardTitleIcon} aria-hidden="true" />
                    </span>}
                    {cardTitle}
                </p>
                <button className="card-header-icon" aria-label="more options" aria-expanded={cardExpanded}>
                    <span className="icon">
                        <i className={`fas ${cardExpanded ? 'fa-minus' : 'fa-plus'}`} aria-hidden="true"></i>
                    </span>
                </button>
            </header>
            <div className={`card-content ${cardExpanded ? '' : 'collapsed'}`}>
                {getCardContent()}
            </div>
        </div>
    );
}

export default Card;
