import './property-details.sass';
import Carousel from '../shared-components/carousel/carousel';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { formatCurrency, splitEvery } from '../../utils/utils';
import { getPropertyById } from '../../services/property';
const PropertyDetailsPage = (props) => {
    const { id } = useParams();
    const [data, setData] = useState(props.data)
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!data) {
            setLoading(true);
            getPropertyById(id)
                .then((property) => {
                    const propertyData = property.data;
                    setData(propertyData);
                    setItems([propertyData?.images?.cover, ...propertyData?.images?.additional]);
                })
                .catch((err) => {
                    console.log('error fetching property details', err);
                    setItems([]);
                })
                .finally(() => setLoading(false));
        } else {
            setItems([data?.images?.cover, ...data?.images?.additional])
        }
    }, []);

    const noInfoText = 'No Info';
    const getPetText = () => {
        if (data.pets) {
            return data.petDeposit ? 'Yes, with deposit' : 'Yes';
        }
        return data.pets === false ? 'No' : noInfoText;
    };

    const getYesNoText = (value) => {
        if (value) {
            return 'Yes';
        }
        return value === false ? 'No' : noInfoText;
    }

    const additionalAmenities = data?.amenities ?? [];
    const additionalAmenitiesSets = splitEvery(additionalAmenities, 4);

    if (loading) {
        return <div className="pf-details app-content">
            <div className="loading-wrapper"></div>
        </div>;
    } else {
        return items?.length && (
            <div className="pf-details app-content">
                <div className="columns is-tablet">
                    <div className="column left-half">
                        <Carousel items={items} itemsToShow={3} itemsToScroll={3}></Carousel>
                    </div>
                    <div className="column right-half">
                        <div className="columns pf-details-section">
                            <div className="column">
                                <h3 className="title is-3">{data.address}</h3>
                            </div>
                            {/* <div className="column is-hidden-mobile">
                                <button className="button is-link">Apply Now</button>
                            </div> */}
                        </div>

                        <div className="columns">
                            {['For Rent', 'Occupied'].indexOf(data.status) > -1 && <div className="column">
                                <p className="pf-details-label">Monthly Rent</p>
                                <span className="pf-details-item">{formatCurrency(data.price)}</span>
                            </div>}
                            {data.status === 'For Sale' && <div className="column">
                                <p className="pf-details-label">Price</p>
                                <span className="pf-details-item">{formatCurrency(data.price)}</span>
                            </div>}
                            <div className="column">
                                <p className="pf-details-label">Bedrooms</p>
                                <span className="pf-details-item">{data.bedrooms}</span>
                            </div>
                            <div className="column">
                                <p className="pf-details-label">Bathrooms</p>
                                <span className="pf-details-item">{data.bathrooms}</span>
                            </div>
                            <div className="column">
                                <p className="pf-details-label">Sq. Ft.</p>
                                <span className="pf-details-item">{data.sqFt}</span>
                            </div>
                        </div>
                        <div className="columns">
                            <div className="column">
                                <p className="pf-details-label">Heating</p>
                                <span className="pf-details-item">{getYesNoText(data.heating)}</span>
                            </div>
                            <div className="column">
                                <p className="pf-details-label">Cooling</p>
                                <span className="pf-details-item">{getYesNoText(data.cooling)}</span>
                            </div>
                            <div className="column">
                                <p className="pf-details-label">Laundry</p>
                                <span className="pf-details-item">{data.laundry ?? noInfoText}</span>
                            </div>
                            <div className="column">
                                <p className="pf-details-label">Pets</p>
                                <span className="pf-details-item">{getPetText()}</span>
                            </div>
                        </div>

                        <div className="pf-details-section">
                            <div className="columns">
                                <div className="column">
                                    <h3 className="title is-3">Description</h3>
                                </div>
                            </div>
                            <div className="columns">
                                <div className="column">
                                    <p>{data.description}</p>
                                </div>
                            </div>
                        </div>
                        {
                            additionalAmenitiesSets.length ? <div className="pf-details-section">
                                <div className="columns">
                                    <div className="column">
                                        <h3 className="title is-3">Additional Amenities</h3>
                                    </div>
                                </div>
                                {
                                    additionalAmenitiesSets.map((amenitiesSet, idx1) => {
                                        const amenitiesSetKey = `amenities-set-${idx1}`;
                                        return <div key={amenitiesSetKey} className="columns">
                                            {amenitiesSet.map((amenity, idx2) => <div key={`${amenitiesSetKey}-amenity-${idx2}`} className="column is-one-quarter">{amenity}</div>)}
                                        </div>;
                                    })
                                }
                            </div> : null
                        }

                        {/* <div className="pf-details-section is-hidden-tablet">
                            <div className="columns">
                                <div className="column">
                                    <button className="button is-link">Apply Now</button>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        );
    }
}

export default PropertyDetailsPage;
