import './home.sass';

const HomePage = () => {
    return (
        <div className="pf-home app-content">
            <figure className="logo-container">
                <img className="logo" src="../images/logo.svg" alt="Peoria's Finest Logo" />
            </figure>
            <div className="pf-tagline">
                <h1>The premier property management company of Peoria.</h1>
            </div>
        </div>
    );
}

export default HomePage;
