import './navbar.sass';
import { Link } from 'react-router-dom';
import { useState } from 'react';

const Navbar = () => {
    const [hamburgerActive, toggleHamburgerActive] = useState(false);
    const closeHamburgerMenu = () => {
        toggleHamburgerActive(false);
    }
    return (
        <nav id="navbar" className="navbar is-fixed-top" role="navigation" aria-label="main navigation">
            <div className="navbar-brand">
                <Link className="navbar-item" to="/">
                    <img src="../images/logo.svg" alt="Peoria's Finest Logo" width="115" />
                </Link>
                <button className={`navbar-burger${hamburgerActive ? ' is-active' : ''}`} aria-label="menu" aria-expanded="false" data-target="pf-navbar-menu" onClick={() => toggleHamburgerActive(!hamburgerActive)}>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                </button>
            </div>

            <div id="pf-navbar-menu" className={`navbar-menu${hamburgerActive ? ' is-active' : ''}`}>
                <div className="navbar-start">
                    <Link className="navbar-item" to="/about" onClick={() => closeHamburgerMenu()}>About</Link>
                    <Link className="navbar-item" to="/contact" onClick={() => closeHamburgerMenu()}>Contact</Link>
                    <Link className="navbar-item" to="/properties" onClick={() => closeHamburgerMenu()}>Properties</Link>
                </div>
                <div className="navbar-end">
                    <Link className="navbar-item" to="/faqs/tenant" onClick={() => closeHamburgerMenu()}>Tenant FAQ</Link>
                    <Link className="navbar-item" to="/faqs/homeowner" onClick={() => closeHamburgerMenu()}>Homeowner FAQ</Link>
                </div>
            </div>
        </nav>
    );
}

export default Navbar;
