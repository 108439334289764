export default {
    api: {
        peoriasFinest: {
            baseUrl: 'https://peorias-finest-api.uw.r.appspot.com',
            endpoints: {
                properties: '/properties',
                inquiry: '/inquiry'
            }
        }
    },
    firebase: {
        apiKey: 'AIzaSyDqT8PTTFklkSKM2NV3RoCG7ILFVK-8gJM',
        // authDomain: 'peorias-finest.firebaseapp.com',
        projectId: 'peorias-finest',
        // storageBucket: 'peorias-finest.appspot.com',
        // messagingSenderId: '894993567660',
        appId: '1:894993567660:web:be61e59584181a5b103409',
        measurementId: 'G-9RZ7YDTLX8'
    }
};