export const storageKeys = {
    deviceId: 'deviceId',
    visitId: 'visitId'
};

export const storageLocation = {
    LOCAL: 'LOCAL',
    SESSION: 'SESSION'
};

const storageConfig = {
    deviceId: {
        keyName: 'deviceId',
        location: storageLocation.LOCAL
    },
    visitId: {
        keyName: 'visitId',
        location: storageLocation.SESSION
    }
};

let localStorage, sessionStorage;

function setItem(key, value) {
    let keyName, location;
    const config = storageConfig[key];

    if (config) {
        location = config.location;
        keyName = config.keyName;
    } else {
        // No pre-defined key. Assume local storage
        location = storageLocation.LOCAL;
        keyName = key;
    }

    if (location === storageLocation.LOCAL) {
        localStorage.setItem(keyName, JSON.stringify(value));
    } else if (location === storageLocation.SESSION) {
        sessionStorage.setItem(keyName, JSON.stringify(value));
    }
}

function getItem(key) {
    let keyName, location;
    const config = storageConfig[key];

    if (config) {
        location = config.location;
        keyName = config.keyName;
    } else {
        // No pre-defined key. Assume local storage
        location = storageLocation.LOCAL;
        keyName = key;
    }

    try {
        if (location === storageLocation.LOCAL) {
            return JSON.parse(localStorage.getItem(keyName));
        } else if (location === storageLocation.SESSION) {
            return JSON.parse(sessionStorage.getItem(keyName));
        }
    } catch (e) {
        // Previous unformated value.
        return undefined;
    }
}

function removeItem(key) {
    let keyName, location;
    const config = storageConfig[key];

    if (config) {
        location = config.location;
        keyName = config.keyName;
    } else {
        // No pre-defined key. Assume local storage
        location = storageLocation.LOCAL;
        keyName = key;
    }

    if (location === storageLocation.LOCAL) {
        localStorage.removeItem(keyName);
    } else if (location === storageLocation.SESSION) {
        sessionStorage.removeItem(keyName);
    }
}

function clear() {
    localStorage.clear();
    sessionStorage.clear();
}

/* ***** Private methods (Start) ***** */

function TemporaryStorage() {
    this.data = {};
    this.getItem = function (key) {
        return this.data[key];
    };
    this.setItem = function (key, value) {
        this.data[key] = value;
    };
    this.removeItem = function (key) {
        const val = this.data[key];
        delete this.data[key];
        return val;
    };
    this.clear = function () {
        this.data = {};
    };
}

function isSessionStorageSupported() {
    if (window.sessionStorage) {
        try {
            // Check for private mode (Safari - sessionStorage not supported in private mode)
            window.sessionStorage.setItem('storageCheck', 'test');
            window.sessionStorage.removeItem('storageCheck');
        } catch (e) {
            // Safari private mode
            return false;
        }
    }
    return true;
}

function isLocalStorageSupported() {
    if (window.localStorage) {
        try {
            // Check for private mode (Safari - localStorage not supported in private mode)
            window.localStorage.setItem('storageCheck', 'test');
            window.localStorage.removeItem('storageCheck');
        } catch (e) {
            // Safari private mode
            return false;
        }
    }
    return true;
}

function init() {
    if (isLocalStorageSupported()) {
        localStorage = window.localStorage;
    } else {
        localStorage = new TemporaryStorage();
    }

    if (isSessionStorageSupported()) {
        sessionStorage = window.sessionStorage;
    } else {
        sessionStorage = new TemporaryStorage();
    }
}

init();

/** **** Private methods (End) ******/

export const storage = {
    getItem,
    setItem,
    removeItem,
    clear
};
