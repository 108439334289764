import './contact.sass';
import { ThankYouScreen } from './thank-you';
import { useEffect, useState } from 'react';
import http from '../../libs/http';
import { getContactUsEndpoint } from '../../config/selectors'
import { trackEvent } from '../../analytics/service';

const ContactPage = () => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const [message, setMessage] = useState('');
    const [formInvalid, setFormInvalid] = useState({
        firstName: null,
        lastName: null,
        email: null,
        message: null
    });
    const [submitting, setSubmitting] = useState(false);
    const [emailSent, setEmailSent] = useState(false);

    useEffect(() => {
    }, []);

    const onSubmit = () => {
        const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
        const invalid = {
            firstName: !firstName,
            lastName: !lastName,
            email: !emailRegex.test(email),
            message: !message
        };

        if (!(invalid.name || invalid.email || invalid.message)) {
            setSubmitting(true);

            // Send email. Backend service needs to be set up still
            const httpOpts = {
                url: getContactUsEndpoint(),
                method: 'post',
                data: {
                    firstName,
                    lastName,
                    email,
                    message
                }
            };
            http.request(httpOpts).promise.then(() => {
                setEmailSent(true);
                trackEvent('submit_contactus_form', { success: true  });
            }).catch(err => {
                console.log('email error', err);
                trackEvent('submit_contactus_form', { success: false, reason: 'Server error' });
            }).finally(() => setSubmitting(false));
        } else if (invalid.email) {
            setEmailError(email ? 'Email not valid.' : 'Please provide an email.');
        }
        trackEvent('submit_contactus_form', { success: false, reason: 'Invalid form' });
        setFormInvalid(invalid);
    };
    return (
        <div className="pf-contact container">
            {emailSent && <ThankYouScreen />}
            {!emailSent && <div className="content">
                <h1 className="title is-2">Contact Us</h1>
                <div className="field-group">
                    <div className="field">
                        <label className="label">First Name</label>
                        <div className="control has-icons-left has-icons-right">
                            <input className="input" type="text" placeholder="First Name" onChange={(e) => setFirstName(e.target.value)} />
                            <span className="icon is-small is-left">
                                <i className="fas fa-user"></i>
                            </span>
                            {formInvalid.firstName && <span className="icon is-small is-right">
                                <i className="fas fa-exclamation-triangle"></i>
                            </span>}
                        </div>
                        {formInvalid.firstName && <p className="help is-danger">Please provide a first name.</p>}
                    </div>
                    <div className="field">
                        <label className="label">Last Name</label>
                        <div className="control has-icons-left has-icons-right">
                            <input className="input" type="text" placeholder="Last Name" onChange={(e) => setLastName(e.target.value)} />
                            <span className="icon is-small is-left">
                                <i className="fas fa-user"></i>
                            </span>
                            {formInvalid.lastName && <span className="icon is-small is-right">
                                <i className="fas fa-exclamation-triangle"></i>
                            </span>}
                        </div>
                        {formInvalid.lastName && <p className="help is-danger">Please provide a last name.</p>}
                    </div>
                </div>

                <div className="field">
                    <label className="label">Email</label>
                    <div className="control has-icons-left has-icons-right">
                        <input className="input" type="email" placeholder="Email" onChange={(e) => setEmail(e.target.value)} />
                        <span className="icon is-small is-left">
                            <i className="fas fa-at"></i>
                        </span>
                        {formInvalid.email && <span className="icon is-small is-right">
                            <i className="fas fa-exclamation-triangle"></i>
                        </span>}
                    </div>
                    {formInvalid.email && <p className="help is-danger">{emailError}</p>}
                </div>

                <div className="field">
                    <label className="label">Message</label>
                    <div className="control has-icons-right">
                        <textarea className="textarea" placeholder="How can we help you?" onChange={(e) => setMessage(e.target.value)}></textarea>
                        {formInvalid.message && <span className="icon is-small is-right">
                            <i className="fas fa-exclamation-triangle"></i>
                        </span>}
                    </div>
                    {formInvalid.message && <p className="help is-danger">Please fill out this field.</p>}
                </div>

                <div className="field is-grouped">
                    <div className="control">
                        <button className={`button is-link ${submitting && 'is-loading'}`} onClick={onSubmit}>Submit</button>
                    </div>
                    <div className="control">
                        <button className="button is-link is-light">Cancel</button>
                    </div>
                </div>
                <div className="columns address">
                    <div className="column">
                        <span className="icon-text">
                            <span className="icon">
                                <i className="fas fa-envelope"></i>
                            </span>
                            <span>401 E Washington St, Box 2033, East Peoria, IL 61611</span>
                        </span>
                    </div>
                </div>
                <div className="columns">
                    <div className="column">
                        <span className="icon-text">
                            <span className="icon">
                                <i className="fas fa-phone"></i>
                            </span>
                            <span>(309) 248-0048</span>
                        </span>
                    </div>
                </div>
            </div>}
        </div>
    );
}

export default ContactPage;
