import './footer.sass';
const Footer = () => {
    return (
        <footer className="footer">
            <div className="content">
                <small>&copy; Copyright 2022, Peoria's Finest, LLC, a Division of The Williams Branch, LLC. All rights reserved.</small>
            </div>
        </footer> 
    );
}

export default Footer;