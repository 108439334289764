import './carousel.sass';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel as ResponsiveCarousel } from 'react-responsive-carousel';

const Carousel = (props) => {
    const { carouselOverrides, items } = props;
    return (
        <div className='carousel-wrapper'>
            <ResponsiveCarousel useKeyboardArrows infiniteLoop>
                {
                    items.map((imageUrl, i) => (
                      <div key={`carousel-item-${i}`}>
                          <img src={imageUrl} alt='' />
                      </div>  
                    ))
                }
            </ResponsiveCarousel>
        </div>
    );
}

export default Carousel;