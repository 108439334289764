export const splitEvery = (array, length) => {
    return array.reduce(
        (result, item, index) => {
            if (index % length === 0) result.push([])
            result[Math.floor(index / length)].push(item)
            return result
        },
        []
    );
};

export const formatCurrency = (num) => {
    return num?.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
    });
};

export const guid = () => {
    // Embedded '4' marks this as a 'version 4' (aka randomly-generated) GUID.
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        /*jslint bitwise: true */
        var r = Math.random() * 16 | 0,
            v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}
