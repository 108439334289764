import { getPropertiesEndpoint } from '../config/selectors';
import http from '../libs/http';

export const getAllProperties = () => {
    const httpOpts = {
        url: getPropertiesEndpoint(),
        method: 'get'
    };
    return http.request(httpOpts).promise;
};

export const getPropertyById = (id) => {
    const httpOpts = {
        url: `${getPropertiesEndpoint()}/${id}`,
        method: 'get'
    };
    return http.request(httpOpts).promise;
};