import './faqs.sass';
import Card from '../shared-components/card/card';

const HomeownerFaqsPage = () => {
    const faqs = [
        {
            cardTitle: 'Why do I need a property management agency?',
            content: [
                'Property managers help take the pressure off homeowners.  We act as a liaison between the tenant and homeowner for repairs and payments.',
                'Management fees are typically paid for by the tenant’s payments.',
                'You can turn your property into an income-producing investment.'
            ]
        },
        {
            cardTitle: 'What criteria are you looking for from a prospective rental property?',
            content: [
                'Well maintained home, condo, townhouse, or apartment with minimal repairs required to make it habitable.',
                'Located in or near a desirable area, town, or city.',
                'Unencumbered and accessible.'
            ]
        },
        {
            cardTitle: 'Do you accept Section 8 tenants?',
            content: 'The homeowner must decide whether to approve Section-8 occupancy.'
        },
        {
            cardTitle: 'How do you market the properties?',
            content: [
                'We will come take professional pictures of your home and gather all the details that prospective tenants want to know.',
                'In addition to listing the property on our company’s webpage, we will also list it on third-party sites to increase views and attract more candidates.'
            ]
        },
        {
            cardTitle: 'Do you screen the tenant?',
            content: 'We perform a thorough candidate pre-screen and will review the prospect with you before proceeding with a lease.'
        },
        {
            cardTitle: 'When is monthly rent paid?',
            content: [
                'The rent is due from the tenant on the 1st of each month.',
                'If the rent is not received by the 5th of the month, a notification will be sent to the homeowner and late fees will be assessed to the tenant.'
            ]
        },
        {
            cardTitle: 'How do I see what rent has been collected on my property?',
            content: [
                'Right now, $$$$contact us$$$$ directly to obtain your income history.',
                'COMING SOON: Owner Portal for Property Income Statements.'
            ],
            linkToInsert: { placeholder: '$$$$', href: '/contact'}
        },
        {
            cardTitle: 'Are utilities included?',
            content: 'Each property is different.  We will work with you to determine what utilities will be paid by the tenant.'
        },
        {
            cardTitle: 'How do I submit a maintenance request?',
            content: [
                'Right now, $$$$contact us$$$$ directly for maintenance requests.',
                'COMING SOON: Owner Portal for Maintenance Requests.'
            ],
            linkToInsert: { placeholder: '$$$$', href: '/contact'}
        },
        {
            cardTitle: 'I don’t see the answer to my question here, who can I talk to?',
            content: 'We would love to help you! Simply $$$$contact us$$$$ for a premium service experience.',
            linkToInsert: { placeholder: '$$$$', href: '/contact'}
        }
    ]

    return (
        <div className="pf-faqs container">
            <div className="content">
                <h1 className="title is-2">Homeowner FAQs</h1>
                <h3>Let us help guide you to success!  Below we answered a few commonly asked questions from homeowners:</h3>
                {faqs.map((faq, index) => <Card key={`homeowner-faq-${index}`} cardTitle={faq.cardTitle} content={faq.content} cardTitleIcon="fas fa-question-circle" linkToInsert={faq.linkToInsert} />)}
            </div>
        </div>
    );
}

export default HomeownerFaqsPage;
