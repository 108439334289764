import './property.sass';
import { Link } from 'react-router-dom';
import { PROPERTY_STATUS_FILTERS } from '../../constants/properties';

const Property = (props) => {
    const data = props.data ?? {};
    const imgSrc = props.imgSrc;
    const { address, city, state, zip, id, status } = data;
    const firstAddressLine = `${address},`;
    const secondAddressLine = `${city}, ${state}, ${zip}`;

    const getStatusClassName = () => {
        if (status === PROPERTY_STATUS_FILTERS.OCCUPIED) {
            return 'is-danger';
        }
        
        if (status === PROPERTY_STATUS_FILTERS.PENDING) {
            return 'is-pending';
        }

        return 'is-success';
    };

    const getPropertyCard = () => (
        <div className="card pf-property">
            {status === PROPERTY_STATUS_FILTERS.PENDING && <span className='overlay-text'>Coming Soon</span>}
            <div className={`card-image ${status === PROPERTY_STATUS_FILTERS.PENDING && 'darken'}`}>
                <figure className="image is-4by3">
                    <img src={imgSrc || 'https://bulma.io/images/placeholders/1280x960.png'} alt="Placeholder" />
                </figure>
            </div>
            <div className="card-content">
                <div className="media">
                    <div className="content">
                        <p>{firstAddressLine}</p>
                        <p>{secondAddressLine}</p>
                        <p className={`help ${getStatusClassName()}`}>Status: {status}</p>
                    </div>
                </div>
            </div>
        </div>
    );

    if (status === PROPERTY_STATUS_FILTERS.PENDING) {
        return getPropertyCard();
    }

    return <Link to={`/details/${id}`}>
        {getPropertyCard()}
    </Link>
        ;
};

export default Property;
