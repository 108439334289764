import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ScrollToTop from '../shared-components/scrollToTop/scrollToTop';
import HomePage from '../home/home';
import AboutPage from '../about/about';
import ContactPage from '../contact/contact';
import PropertiesPage from '../properties/properties';
import PropertyDetailsPage from '../property-details/property-details';
import HomeownerFaqsPage from '../faqs/homeowner-faqs';
import TenantFaqsPage from '../faqs/tenant-faqs';

const AppRouter = () => {
    return (
        <>
            <ScrollToTop /> {/* This is needed to deal with a React Router bug where the page doesn't scroll to the top on a new route */}
            <Switch>
                <Route path='/about'>
                    <AboutPage />
                </Route>
                <Route path='/contact'>
                    <ContactPage />
                </Route>
                <Route path='/properties'>
                    <PropertiesPage />
                </Route>
                <Route path='/details/:id'>
                    <PropertyDetailsPage />
                </Route>
                <Route path='/faqs/homeowner'>
                    <HomeownerFaqsPage />
                </Route>
                <Route path='/faqs/tenant'>
                    <TenantFaqsPage />
                </Route>
                <Route path='/'>
                    <HomePage />
                </Route>
            </Switch>
        </>
    );
};

export default AppRouter;