import './app.sass';
import Navbar from '../shared-components/navbar/navbar';
import AppRouter from './app-router';
import Footer from '../shared-components/footer/footer';

function App() {
    return (
        <div className="app-container">
            <Navbar />
            <AppRouter />
            <Footer />
        </div>
    );
}

export default App;
