import './about.sass';
const AboutPage = () => {
    return (
        <div className="container content">
            <div className="pf-box">
                <div className="pf-box-inner">
                    <h1 className="title is-2">About Peoria's Finest</h1>
                    <p>The Williams Branch presents Peoria's Finest, a family-owned and operated upscale property management agency. We have over five decades of Real Estate experience that spans from residentials to railroads - yes, we are well versed in all aspects of the real estate industry!  We provide everyone with white glove service, no matter how big, small or complex the transaction is.</p>
                    <p>Why should you use our services?</p>
                    <ul>
                        <li>We stay informed and connected in the market and are often able to leverage that knowledge in an advantageous way for you.</li>
                        <li>Even though we market ourselves as upscale, we do not believe that should come at an exorbitant price. We aim to stay competitive, and we have homes that fit all price points and needs.</li>
                        <li>We pride ourselves on providing unparalleled service; we respond rapidly and will go out of the way to meet your needs.</li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default AboutPage;
