import './faqs.sass';
import Card from '../shared-components/card/card';

const TenantFaqsPage = () => {
    const faqs = [
        {
            cardTitle: 'What documentation will I need to move in?',
            content: [
                'Government-issued ID or passport.',
                'Two months check stubs, bank statements (if self-employed), tax return (if self-employed), or SS statement (if receiving disability).'
            ]
        },
        {
            cardTitle: 'What criteria are you looking for from a tenant?',
            content: [
                'All information will be verified on your application.',
                'Ensure you have good, verifiable rental history.',
                'Credit score matters.',
                'Background reports will be reviewed for each applicant.',
                'Must be over 18 to apply.'
            ]
        },
        {
            cardTitle: 'How long does it take to process my application?',
            content: [
                'All applications are reviewed within forty-eight (48) hours or less from the time that you apply.',
                'If you apply through Zillow or other third-party, the application fee charged does not come to our company (you will need to work with that third-party company if you have any issues).',
                'If you apply directly to our company, there is a thirty dollar ($30.00) non-refundable application fee, per applicant, which covers the costs associated with the credit and background check.',
                'If your application is not approved, we will contact you to let you know what you will need to work on to get an approval.'
            ]
        },
        {
            cardTitle: 'How much do I need to move in?',
            content: [
                'You will need the full deposit and 1st month’s rent when you sign the lease.',
                'If a lease is signed more than five (5) days prior to the 1st of the next month, there may be a delay in receiving the keys or additional rent may be due.'
            ]
        },
        {
            cardTitle: 'When is rent due?',
            content: [
                'The rent is due on the 1st of each month.',
                'Some leases allow for a grace period. Refer to the terms in your lease for more information.',
                'If you do not pay on time a late fee will be applied, or more serious action will be taken. Refer to the terms in your lease for more information.'
            ]
        },
        {
            cardTitle: 'How do I pay my rent?',
            content: [
                'Right now, $$$$contact us$$$$ directly to obtain payment instructions.',
                'COMING SOON: Tenant Portal for Payments.'
            ],
            linkToInsert: { placeholder: '$$$$', href: '/contact'}
        },
        {
            cardTitle: 'Do you accept Section 8?',
            content: 'Some properties are eligible for Section 8 vouchers.  Refer to the property description in the $$$$Properties$$$$ section.',
            linkToInsert: { placeholder: '$$$$', href: '/properties'}
        },
        {
            cardTitle: 'Are utilities included?',
            content: 'Each property is different.  Refer to the property description in the $$$$Properties$$$$ section.',
            linkToInsert: { placeholder: '$$$$', href: '/properties'}
        },
        {
            cardTitle: 'How do I submit a maintenance request?',
            content: [
                'Right now, contact us directly for maintenance requests.',
                'COMING SOON: Tenant Portal for Maintenance Requests.'
            ],
            linkToInsert: { placeholder: '$$$$', href: '/contact'}
        },
        {
            cardTitle: 'I don’t see the answer to my question here, who can I talk to?',
            content: 'We would love to help you! Simply $$$$contact us$$$$ for a premium service experience.',
            linkToInsert: { placeholder: '$$$$', href: '/contact'}
        }
    ]

    return (
        <div className="pf-faqs container">
            <div className="content">
                <h1 className="title is-2">Tenant FAQs</h1>
                <h3>Let us help guide you to success! Below we answered a few commonly asked questions from prospective tenants:</h3>
                {faqs.map((faq, index) => <Card key={`tenant-faq-${index}`} cardTitle={faq.cardTitle} content={faq.content} cardTitleIcon="fas fa-question-circle" linkToInsert={faq.linkToInsert} />)}
            </div>
        </div>
    );
}

export default TenantFaqsPage;
